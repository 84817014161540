import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" pv="5px" alt="Mr Bro About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto">
          <CFView
            row
            center
            w="100%"
            maxWidth="1400px"
            style={{ backgroundAttachment: 'fixed' }}
          >
            <CFImage src={about} w="90%" alt="Promotions" />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
